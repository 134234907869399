import React from 'react';
import { Link, Routes, Route } from 'react-router-dom';
import RegAlumno from './Registro_Alumno';
import RegMaestro from './Registro_Maestro';
import RegAdministrador from './Registro_Administrador';
import './Registro_main.css';
import backgroundImage from '../imagenes/F_logo.jpg'; // Asegúrate de que esta ruta sea correcta

const RegistroMain = () => {
  return (
    <div className="registro-main" style={{ backgroundImage: `url(${backgroundImage})` }}>
      <h1>Registro de Usuario</h1>
      <div className="registro-links">
        <Link to="registro-alumno">Registro de Alumno</Link>
        <Link to="registro-maestro">Registro de Maestro</Link>
        <Link to="registro-administrador">Registro de Administrativo</Link>
      </div>
      <Routes>
        <Route path="registro-alumno" element={<RegAlumno />} />
        <Route path="registro-maestro" element={<RegMaestro />} />
        <Route path="registro-administrador" element={<RegAdministrador />} />
      </Routes>
    </div>
  );
};

export default RegistroMain;
