import React from 'react';
import { useNavigate } from 'react-router-dom'; // Importa useNavigate para la navegación
import backgroundImage from '../imagenes/F_logo.jpg'; // Importa la imagen de fondo
import './Welcome.css'; // Importa los estilos CSS

const Welcome = () => {
  const navigate = useNavigate();

  const handleQuizRedirect = () => {
    navigate('/quiz'); // Redirige a la página del cuestionario
  };

  return (
    <div className="welcome-container" style={{ backgroundImage: `url(${backgroundImage})` }}>
      <div className="welcome-content">
        <h2>¡Bienvenido!</h2>
        <p>Tu registro ha sido exitoso.</p>
        <button onClick={handleQuizRedirect} className="quiz-button">Cuestionario</button>
      </div>
    </div>
  );
};

export default Welcome;
