import React from 'react';
import { Routes, Route } from 'react-router-dom';
import RegistroMain from './components/Registro_main';
import Welcome from './components/Welcome';
import Quiz from './components/Quiz';

function App() {
  return (
    <div className="App">
      <Routes>
        <Route path="/*" element={<RegistroMain />} />
        <Route path="/welcome" element={<Welcome />} />
        <Route path="/quiz" element={<Quiz />} />
      </Routes>
    </div>
  );
}

export default App;
