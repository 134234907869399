import React, { useEffect, useState } from 'react';
import Papa from 'papaparse'; // Asegúrate de instalar esta librería con `npm install papaparse`
import backgroundImage from '../imagenes/F_logo.jpg'; // Usa la misma imagen de fondo
import './Quiz.css'; // Importa los estilos CSS

const Quiz = () => {
  const [questions, setQuestions] = useState([]);
  
  useEffect(() => {
    fetch('/questions.csv')
      .then(response => response.text())
      .then(data => {
        const parsedData = Papa.parse(data, { header: true });
        setQuestions(parsedData.data);
      });
  }, []);

  return (
    <div className="quiz-container" style={{ backgroundImage: `url(${backgroundImage})` }}>
      <div className="quiz-content">
        <h1>Cuestionario</h1>
        <ul>
          {questions.map((question, index) => (
            <li key={index}>{question.question}</li>
          ))}
        </ul>
      </div>
    </div>
  );
};

export default Quiz;
